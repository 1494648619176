import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import ExpandPanel from '../../../components/expandPanel/expandPanel';
import GlobeIcon from '../../../images/GlobeIcon.inline.svg';
import { languageObjectArray } from '../../../lib/maps/languageMap';
import {LangContext} from '../../../context/LocaleContext';

/**
 * LanguageSelect
 * 
 */
const LanguageSelect = ({className, locale, ...other}) => {
  const { changeLanguage } = useContext(LangContext);    

  const LanguageRadio = ({language, current}) => {
    let isCurrent = current === language.cmsLocale.toLowerCase();
    return  <li key={language.cmsLocale} >
              <input id={`lang--${language.cmsLocale}--${className || ''}`} defaultChecked={isCurrent} type="radio" className={"language"} name={`locale selector`} onMouseDown={(e) =>{changeLanguage(language.cmsLocale)} } onKeyPress={(event)=>{if(event.key === 'Enter'){changeLanguage(language.cmsLocale)}}}/>
              <span />                        
              <label htmlFor={`lang--${language.cmsLocale}--${className || ''}`} className={`${isCurrent ? 'current' : ''}`} >
                {language.label}
              </label>
            </li>
  };

  return (
    <div className={`header__main__right__languageSelect__container ${className || ''}`} {...other}>
      <ExpandPanel hasNavigated={locale} className="language__btn" uniqueid={`languagePicker-${className}`} aria-label={'Toggle Language Picker'} buttonContent={<GlobeIcon/>} icon={false} panelClassName="header__main__right__languageSelect">
        <ul className="container">
          {
            languageObjectArray?.map((language, i) => <LanguageRadio key={i} language={language} current={locale.toLowerCase()} />) 
          }           
        </ul>
      </ExpandPanel>
    </div>
  );
};

LanguageSelect.propTypes = {
  /**
   * optional classname
   */
   className: PropTypes.string,
  /**
   * Current locale
   */
  locale: PropTypes.string.isRequired
}

export default LanguageSelect;