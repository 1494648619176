import React from 'react';
import { graphql } from "gatsby";
import PropTypes from 'prop-types';
import HtmlBlock from '../htmlBlock/htmlBlock';
import Link from '../link/link';
import NavigationBlock from '../navigationBlock/navigationBlock';
import Image from '../image/image';
import { ConditionalWrapper } from '../../lib/functions/util-functions';

/**
 * 
 * Handles the following content block structures -
 * CMS: DatoCmsComponentEyebrowHeadingCopyLink, DatoCmsComponentHeadingCopyLink, DatoCmsComponentHeadingImageCopyLink, DatoCmsComponentHeadingImageCopy, DatoCmsComponentHeadingSubheadingCopy, DatoCmsComponentCopyLink
 * 
 * any combination of the following elements: Image, Eyebrow, Heading, Subheading, copy, alignment
 */
const contentBlock = ({children, className, imgWidth, imgHeight, data, headingTag, linkclass, linktag, linkWrap, noLink, ...other}) => {
  const {alignment, content, eyebrow, heading, image, linkS, subheading  } = data || {};
  return (
    <div className={`contentBlock ${className || ''} ${alignment || ''} `} {...other}>
      {
        image && <Image data={image} width={imgWidth || undefined} height={imgHeight || undefined} />
      }
      <div className="contentBlock__allContent">
        {
          eyebrow && <span className={`contentBlock__eyebrow h4`}>{eyebrow}</span>
        }

        {
          heading && <ConditionalWrapper condition={linkWrap && linkS && linkS.length > 0} wrapper={children => <Link icon={false} className="titleLink" data={linkS[0]}>{children}</Link>}><HtmlBlock tag={headingTag} data={heading} className={`contentBlock__heading`} /></ConditionalWrapper>
        }
        {
          subheading && <HtmlBlock tag={heading ? "span" : headingTag} className="h3 contentBlock__subheading" data={subheading} />
        }
        {
          content && <HtmlBlock data={content.includes('<p>') ? content : `<p>${content}</p>`} className={`contentBlock__content`}/>
        }
        {
          (linkS && !noLink && (linkS.length > 0)) && <NavigationBlock className="allLinks" linkclass={linkclass} linktag={linktag} data={linkS}/>
        }
      </div>
    </div>
  );
};

contentBlock.propTypes = {
  /**
  * Insert any content (placement below heading)
  */
  children: PropTypes.any,
  /**
  * Classname
  */
  className: PropTypes.string,
  /**
  * Data from cms
  */
  data : PropTypes.shape({
    alignment: PropTypes.oneOf(['Left', 'Right', 'Top']),
    content: PropTypes.string,
    eyebrow: PropTypes.string,
    heading: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    linkS: PropTypes.array,
    subheading: PropTypes.string,
  }).isRequired,
  /**
  * type of heading
  */
  headingTag: PropTypes.oneOf(['h1', 'h2', 'h3']),
  /**
  * className for links
  */
  linkclass: PropTypes.string,
  /**
  * set true to wrap entire block with link
  */
  linkWrap: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  noLink: PropTypes.bool

};

contentBlock.defaultProps = {
  className: 'contentBlock',
  headingTag: 'h2',
  linkclass : "link--featured--primary",
  linkWrap: false,
  noLink: false
};

export default contentBlock;

export const EyebrowHeadingCopyLinkFragment = graphql`
  fragment EyebrowHeadingCopyLinkFragment on DatoCmsComponentEyebrowHeadingCopyLink {
    moduleID: id
    eyebrow
    heading
    content
    linkS{
      ...LinkFragment
    }
  }`;

  export const HeadingCopyLinkFragment = graphql`
  fragment HeadingCopyLinkFragment on DatoCmsComponentHeadingCopyLink {
    moduleID: id
    heading
    content
    linkS{
      ...LinkFragment
    }
  }`;

export const HeadingImageCopyLinkFragment = graphql`
  fragment HeadingImageCopyLinkFragment on DatoCmsComponentHeadingImageCopyLink {
    moduleID: id
    heading
    content
    image{
      alt
      gatsbyImageData(width: 150)
      url
      height
      width
    }
    linkS{
      ...LinkFragment
    }
  }`;

  export const HeadingImageCopyFragment = graphql`
  fragment HeadingImageCopyFragment on DatoCmsComponentHeadingImageCopy{
    moduleID: id
    heading
    content
    image{
      ...HalfImageFragment
    }
  }`;

export const HeaderSubCopyFragment = graphql`
  fragment HeaderSubCopyFragment on DatoCmsComponentHeadingSubheadingCopy {
    moduleID: id
    alignment
    content
    heading
    subheading
    placeholderStills{
      ...HalfImageFragment
    }
  }`;


  export const CopyLinkFragment = graphql`
  fragment CopyLinkFragment on DatoCmsComponentCopyLink {
    moduleID: id
    content
    linkS{
      ...LinkFragment
    }
  }`;