import React from 'react';

/**
 * Renders the correct Template component to the page
 * 
 * @functions
 * @category Renders
 * @summary Render arrays of CMS data according to provided component maps
 * @param {array} data - Array of DatoCMS content types & associated data
 * @param {map} data - Map from lib/maps (or custom map)
 * @return {Array}
 * 
 */

const Render = ({data, map, ...other}) => {

  if(!Array.isArray(data)){
    data = [data];
  }

  return (
    (data && map) ?
    data.map((element, index) => {
      const Component = map[element?.__typename];
      if(!Component){
        console.warn(element?.__typename || element, ' element attempting to render that does not exist in the map');
      }
      return Component ? <Component key={index} moduletype={element.__typename} data={element} {...other} /> : null
    })
    :
    false
  )
};

export default Render;
