import React, {useEffect, useState} from 'react';
import { graphql } from "gatsby";
import PropTypes from 'prop-types';
import cleanHTML from '../../lib/functions/sanitize';

/**
 * Component to sanitize and decode html block
 */
const HtmlBlock = ({children, className, data, tag, ...other}) => {
  //Can accept table component data, data prop, or children
  data = data?.table || data || children;

  if(tag && data){
    if(Array.isArray(data)){
      data=data.join('');
    }
    data = data?.replaceAll(/<p>|<\/p>/g, '');
  }

  let [content, setContent] = useState(data);

  const trademarkSymbols = ['®', '™'];
  const DefaultTag = tag ? tag.toString() : 'div';


  useEffect(() => {
    /**
    * decode reserved characters
    */
    const decodeHTML =  (html) => {
        var txt = document.createElement('textarea');
        txt.innerHTML = html;
        return txt.value;
    };

    if ( (typeof document !== 'undefined') && data) {
      //remove extra p tags
      let decoded = decodeHTML(data);
      setContent(cleanHTML( decoded ));
    }

  }, [data, children]);

  /**
  * wrap all trademark chars in <sup> tags
  */
  if(content){
    trademarkSymbols.forEach(element => {
      let regex = new RegExp(`((?!>s*))${element}((?!s*</sup>))`, 'gi')
      content = content?.replace(regex, `<sup className="trademark">${element}</sup>`);
    });
  }

 

  return (
    content ? 
      <DefaultTag className={`${className || ''} htmlBlock`} {...other} dangerouslySetInnerHTML={{'__html': content }}/> 
    : false     
  );
};

HtmlBlock.propTypes = {
  /**
  * optional className
  */
  className: PropTypes.string,
  /**
  * Data from CMS
  */
  data : PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default HtmlBlock;

export const TableFragment = graphql`
fragment TableFragment on DatoCmsComponentTable {
  id: originalId
  table
}`