import React, {useContext} from 'react';
import Button from '../../button/button';
import {ModalContext} from '../../../context/ModalContext';

/**
* Displays overlay and modal
* 
*/
const ModalTrigger = ({id, data, children, modalClasses, ...other }) => {
  const { toggleModal } = useContext(ModalContext) || {};
  return (
    <Button {...other} id={id} onClick={()=>toggleModal(data, id, modalClasses)}>
      {children}
    </Button>
  );

};

export default ModalTrigger;


