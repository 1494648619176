import React from 'react';
import PropTypes from 'prop-types';

/**
 * Loader
 */
const Loading = ({className, ...other}) => {
  return (
    <div className="loader">
      <div className={`${className || ''}`} role="alert" aria-busy="true" {...other}/>
    </div>
  )
}

export default Loading

Loading.propTypes = {
  //defaults: loader--circle, loader--dots
  className: PropTypes.string,
};

Loading.defaultProps = {
  className: ''
}