import React from "react";
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from "gatsby";
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { buildSchema } from '../schema/schema';

const SEO = ({customSchema, data, locale, passwordProtected, preload, schemaType, url, nonlocalepath, ...other}) => {

  const  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  if(!data){
    data = {
      'tags':[
        {
          tagName: 'title', 
          content: capitalizeFirstLetter(nonlocalepath?.replaceAll('/', '')) + ' | 75F'
        }
      ]
    };
  }

  const { datoCmsSite, site, allDatoCmsGlobalHeader } = useStaticQuery(
    graphql`
      query {
        allDatoCmsGlobalHeader{
          nodes{
            logo{
              url
            }
            locale
          }
        }
        site{
          siteMetadata{
            title
          }
        }
        datoCmsSite {
          faviconMetaTags {
            ...GatsbyDatoCmsFaviconMetaTags
          }
        }
      }
    `
  );

  let schemaOptions = {
    type : schemaType,
    seoData: data, 
    url: url,
    siteTitle: site?.siteMetadata?.title,
    logo: allDatoCmsGlobalHeader.nodes.find((logo)=>logo.locale === locale)?.logo.url,
    author: other?.author
  }

  return (
    <HelmetDatoCms seo={data} favicon={datoCmsSite?.faviconMetaTags}>
      <html lang={locale} />
      
      <link rel="canonical" href={process.env.GATSBY_URL + nonlocalepath} />
      
      {
        preload && <link rel="preload" href={preload} as="image"/>
      }  

      <link rel="preconnect" href="https://use.typekit.net" crossorigin />
      <link rel="preconnect" href="https://p.typekit.net" crossorigin />
      <link rel="preload" href={`https://use.typekit.net/${process.env.GATSBY_TYPEKIT_ID}.css`} as="style" />

      {
        passwordProtected && <meta name="robots" content="noindex" />
      }

      {
        customSchema &&
        <script type="application/ld+json">
          {customSchema}
        </script>
      }
      
      {
        buildSchema(schemaOptions) && 
        <script type="application/ld+json">
          {JSON.stringify(buildSchema(schemaOptions))}
        </script>
      }

      <link rel="stylesheet" href={`https://use.typekit.net/${process.env.GATSBY_TYPEKIT_ID}.css`} media="print" onload="this.media='all'" />  


    </HelmetDatoCms>
  )
}

SEO.propTypes = {
  /**
   * DATO SEO field
   */
  data : PropTypes.object,
  /**
   * current locale
   */
  locale: PropTypes.string
}

export default SEO;