import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

/**
 * CMS Asset
 * 
 * Uses Dynamic Gatsby Plugin Image Component
 * 
 * Supports Lazy Loading
 * 
 * Loads the optimal image size
 * 
 */
 const Image = ({
  alt, caption, className, data, decorative, layout, lazyload, height, width, ...other
}) => {
  // Gatsby plugin image or svg
  const { alt: defaultAlt, gatsbyImageData, title, url, height: defaultHeight, width: defaultWidth, focalPoint } = data || {};

  const finalCaption = caption ? (typeof caption === 'string' ? caption : false || title) : false;
  const displayAlt = (decorative === true) ? false : (alt || defaultAlt || title || false);

  // Conditional wrapper 
  const ImageWrapper = ({ condition, wrapper, children }) => (
    condition ? wrapper(children) : <>{children}</>
  );

  // Adjust styling based on layout and aspect ratio
  const imgAttr = {
    className: `${finalCaption ? '' : 'img'} ${className || ''} ${defaultHeight > defaultWidth ? 'portrait' : 'landscape'} ${layout ? `layout-${layout}` : ''}`,
    style: {
      '--x': focalPoint?.x ? `${focalPoint.x * 100}%` : '50%',
      '--y': focalPoint?.y ? `${100 - (focalPoint.y * 100)}%` : '50%',
      height: height ? `${height}px` : undefined,
      width: width ? `${width}px` : undefined,
    },
    ...other
  };

  return (
    <ImageWrapper
      condition={finalCaption}
      wrapper={children => <figure className='img'>{children}</figure>}
    >
      {
        (gatsbyImageData?.src || gatsbyImageData?.images) ? (
          <GatsbyImage
            loading={lazyload ? 'lazy' : 'eager'}
            alt={displayAlt || ""}
            image={gatsbyImageData}
            {...imgAttr}
          />
        ) : (
          url && (
            <div {...imgAttr}>
              <img
                className="svg"
                loading={lazyload ? 'lazy' : ''}
                alt={displayAlt || ""}
                src={url}
                height={height || defaultHeight}
                width={width || defaultWidth}
              />
            </div>
          )
        )
      }
      {
        finalCaption && <figcaption>{finalCaption}</figcaption>
      }
    </ImageWrapper>
  );
};


Image.propTypes = {
  /**
   * Alt tag, leave blank for decorative
   */
  alt: PropTypes.string,
  /**
   * Display title/description as caption if available
   * Takes caption/string or bool to use CMS title/description
   */
  caption: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  /**
   * Image className
   */
  className: PropTypes.string,
  /**
   * From CMS
   */
  data: PropTypes.shape({
    /**
     * CMS image alt
     */
    alt: PropTypes.string,
    /**
     * Gatsby Image Data generated by Gatsby
     */
    gatsbyImageData: PropTypes.object,
    /**
     * CMS image title
     */
    title: PropTypes.string,
    /**
     * File path (used if gatsbyImageData is unavailable)
     */
    url: PropTypes.string,
    /**
     * Default image height
     */
    height: PropTypes.number,
    /**
     * Default image width
     */
    width: PropTypes.number,
  }),
  /**
   * Indicates a decorative image (empty alt)
   */
  decorative: PropTypes.bool,
  /**
   * Custom image height
   */
  height: PropTypes.number,
  /**
   * Custom image width
   */
  width: PropTypes.number,
};

Image.defaultProps = {
  caption: false,
  decorative: true,
  lazyload: true
};

export default Image;

Image.propTypes = {
  /**
  * Alt tag, leave blank for decorative
  */
  alt: PropTypes.string,
  /**
  * Display title/description as caption if available
  * Takes caption/string or bool to use cms title/description
  */
  caption: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  /**
  * Image className
  */
  className: PropTypes.string,
  /**
  * From CMS
  */
  data: PropTypes.shape({
    /**
    * CMS image alt
    */
    alt: PropTypes.string,
    /**
    * Gatsby Image Data generated by gatsby
    */
    gatsbyImageData: PropTypes.object,
    /**
    * CMS image title
    */
    title: PropTypes.string,
    /**
    * File path (used if gatsbyImageData is unavailable)
    */
    url: PropTypes.string
  }),
  /**
   * Indicates a decorative image (empty alt)
   */
  decorative : PropTypes.bool,
};

Image.defaultProps = {
  caption: false,
  decorative: true,
  lazyload: true
};

// full screen background image
export const BackgroundImageFragment = graphql`
fragment BackgroundImageFragment on  DatoCmsFileField{
  gatsbyImageData(layout: FULL_WIDTH, imgixParams: {fit: "fill", dpi: 92, q: 65}, width: 1500)
  focalPoint{
    x
    y
  }
}`;

//full container
export const ContainerImageFragment = graphql`
fragment ContainerImageFragment on  DatoCmsFileField{
  alt
  gatsbyImageData(layout: CONSTRAINED, imgixParams: {fit: "clamp", dpi: 92, q: 65}, width: 1440)
  title
  url
  height
  width
  focalPoint{
    x
    y
  }
}`;

//half screen
export const HalfImageFragment = graphql`
fragment HalfImageFragment on  DatoCmsFileField{
  alt
  gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED, imgixParams: {fit: "clamp", dpi: 92, q: 75}, width: 720)
  title
  url
  height
  width
  focalPoint{
    x
    y
  }
}`;

//third screen
export const ThirdImageFragment = graphql`
fragment ThirdImageFragment on  DatoCmsFileField{
  alt
  gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED, imgixParams: {fit: "clamp", dpi: 92, q: 75}, width: 400)
  title
  url
  height
  width
  focalPoint{
    x
    y
  }
}`;

//small related image
export const RelatedImageFragment = graphql`
fragment RelatedImageFragment on  DatoCmsFileField{
  alt
  gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED, imgixParams: {fit: "clamp", dpi: 92, q: 75}, width: 200)
  title
  url
  height
  width
  focalPoint{
    x
    y
  }
}`;

export const ImageFragment = graphql`
fragment ImageFragment on  DatoCmsFileField{
  alt
  gatsbyImageData(layout: CONSTRAINED,imgixParams: {dpi: 92, q: 75, fit: "max"})
  title
  url
  height
  width
}`;

export const LogoFragment = graphql`
fragment LogoFragment on  DatoCmsFileField{
  alt
  gatsbyImageData(placeholder: BLURRED,layout: CONSTRAINED,imgixParams: {dpi: 92, q: 75, fit: "max"}, width: 100)
  title
  url
  height
  width
}`;

export const ImageFragmentSrc = graphql`
fragment ImageFragmentSrc on  DatoCmsFileField{
  url
}`;