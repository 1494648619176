import React from 'react';
import PropTypes from 'prop-types';
import ExpandPanel from '../../../../components/expandPanel/expandPanel';
import HtmlBlock from '../../../../components/htmlBlock/htmlBlock';
import RenderNav from '../../../../lib/renders/renderNav';

/**
 * SubItem
 * 
 */
const SubItem = ({currentstate, data, isopen, linkclass, ...other}) => {
  // console.log('subItem', data);
  const ToggleButton = data.heading ? <HtmlBlock tag="h2" data={data.heading} /> : '';
  return (
    <>
      {
        //if there's a heading wrap in a panel for mobile
        data.heading ? 
        <ExpandPanel currentstate={currentstate} {...other} className="megaMenuItem__col2__sub__btn mobile" panelClassName="megaMenuItem__col2__sub" buttonContent={ToggleButton} icon={true} isopen={isopen}>             
          <RenderNav tag='h2' data={[data]} linkclass={linkclass} />
        </ExpandPanel>
        :
        <RenderNav data={[data]} {...other} linkclass={linkclass}/>
      }  
    </>
  );
};

SubItem.propTypes = {
  /**
   * function to lift panel's current state
   */
  currentstate: PropTypes.func,
   /**
   * CMS content
   */
  data: PropTypes.object,
   /**
   * Current panel state
   */
  isopen: PropTypes.bool,
   /**
   * Class passed directly to links
   */
  linkclass: PropTypes.string
}

export default SubItem;


 