import React from "react";
import {Script} from "gatsby";
import Layout from "./src/globals/layout";
import { ModalProvider } from "./src/context/ModalContext";

import "@fontsource/oswald/200.css";
import "@fontsource/oswald/300.css";
import "@fontsource/oswald/400.css";
import "@fontsource/oswald/500.css";
import "@fontsource/oswald/700.css";

export const onRouteUpdate = ({location}) => {
  scrollToAnchor(location);
  return;
}

export const shouldUpdateScroll = ({
  routerProps: { location },
}) => {
  if(location?.hash){
    scrollToAnchor(location);
  }
  return location?.hash ? false : true;
};

const queryCheck = (s) => document.createDocumentFragment().querySelector(s)

const isSelectorValid = (selector) => {
  try { queryCheck(selector) } catch { return false }
  return true
}

function waitForElm(selector) {
  return new Promise(resolve => {
    if (isSelectorValid(selector) && document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }
    const observer = new MutationObserver(mutations => {
        if (isSelectorValid(selector) && document.querySelector(selector)) {
            resolve(document.querySelector(selector));
            observer.disconnect();
        }
    });

    observer.observe(document.querySelector(`#main`), {
        childList: true,
        subtree: true
    });
  });
}

/**
*
* @desc - a function to jump to the correct scroll position
* @param {Object} location -
* @param {Number} [mainNavHeight] - the height of any persistent nav -> document.querySelector(`nav`)
*/
function scrollToAnchor(location) {
  let scrolled = false;
  if (location && location.hash) {
    if(isNaN(location.hash[1])){
      waitForElm(location.hash).then((item) => {
        if(item &&  !scrolled){
          item.scrollIntoView(true);  
          //TODO: Timeout isn't ideal. Look for another solution to anchor to lazy rendered modules
          //https://stackoverflow.com/questions/56762877/lazy-loading-and-scrollintoview-angular2version-7
          setTimeout(_ => {
            item.scrollIntoView(true);
            scrolled = true;
           }, 400);
        }
      });
    }
  }
  return true
}


export const wrapPageElement = ({ element, ...restProps }, ...args) => {
  return (
    <Layout 
      props={restProps}
      args={args}
      >
 {/* <!-- Global site tag (gtag.js) - Google Analytics --> 
      <script src="https://www.googletagmanager.com/gtag/js?id=G-3KP094ET3E" strategy="idle"></script>
       <script strategy="idle">
       {
          `window.dataLayer = window.dataLayer || [];
           function gtag(){dataLayer.push(arguments);}
           gtag('js', new Date());

           gtag('config', 'G-3KP094ET3E');
         `
        }
        </script>*/}
        {element}
    </Layout>  
  )  
};

export const wrapRootElement = ({ element }) => <ModalProvider>{element}</ModalProvider>;
