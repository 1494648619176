import React, {useEffect, useCallback, useState} from "react";
import { Script } from "gatsby";
import  Loader from '../loading/loading';
import Button from '../button/button';
import PropTypes from 'prop-types';

/**
 * Renders All Wistia videos
 * https://wistia.com/support/developers/player-api
 */
const Wistia = ({children, className, controls, id, isCurrent, passVideoState, quality, style, videoCallBack, videoSegment, videoType, ...other }) => { 
  const [ videoGlobal, setVideoGlobal ] = useState(false);

  let classIn = "wistia_embed";
  let script = 'https://fast.wistia.com/assets/external/E-v1.js';  

  if(videoType === 'Channel'){
    script = 'https://fast.wistia.net/assets/external/channel.js';
    classIn = "wistia_channel mode=popover"; 
  }


  // stabilize functions to prevent rerenders //
  const videoCallBackStable = useCallback((args) => {
    if(videoCallBack){
      videoCallBack(args);
    }
  }, [videoCallBack]) 

  const passVideoStateStable = useCallback((args) => {
    if(passVideoState){
      passVideoState(args);
    }
  }, [passVideoState]) 

  /**
   * Wistia Render
   */    
  let hideControls = (controls === false) ? 'autoPlay=true controlsVisibleOnLoad=false playbar=false smallPlayButton=false fullscreenButton=false volumeControl=false playbackRateControl=false qualityControl=false playButton=false settingsControl=false' : '';
  

  useEffect(() => {

    const applyConfig = (id) => {
      if(typeof window !== `undefined`) {
        window._wq = window._wq || [];
          window._wq.push({ 
          id: id,
          onReady: (video) => { 
            setVideoTime(videoSegment, video); 
            setVideoGlobal(video); 
            if(passVideoStateStable){
              passVideoStateStable({id: id, 'status': 'ready'});
            }
          }
        });
      }
    }
  
    const setVideoTime = (videoSegment, video ) => {
      if(video && videoSegment && videoSegment.length === 2){
        video.mute();
        let start = videoSegment ? parseInt(videoSegment[0]) : null;
        let end = videoSegment ? parseInt(videoSegment[1]) : null;
        if(parseInt(start) < parseInt(end)){  
          // console.log('set video time running', videoSegment, id);
          passVideoStateStable({id: id, 'status': 'playing'});
          video.time(start);
          video.play();      
          video.bind("betweentimes", start, end, function(withinInterval) {
            if (!withinInterval) {
              // console.log('notwithin interval, PAUSED', videoSegment, id);
              video.pause();
              passVideoStateStable({id: id, 'status': 'paused'});
              videoCallBackStable({type: 'switchVideo'});
              return video.unbind;
            }
          });
        } 
      }
      else{
        video.pause();
        passVideoStateStable({id: id, 'status': 'paused'});
      }
    }

    if(videoType==='City'){
      if(videoGlobal){
        setVideoTime(videoSegment, videoGlobal);
      }else{
        applyConfig(id);
      }
    }
    
  }, [videoSegment, id, videoCallBackStable, videoGlobal, passVideoStateStable, videoType]);


  return (
    <>
    {
      script && 
      <Script src={script} />
    }
    {
      videoType !== 'PopOver' &&
      <Script src={`https://fast.wistia.com/embed/medias/${id}.jsonp`} />
    }
    { 
      videoType === 'PopOver' ?
        <span className={`wistia_async_${id} popover=true popoverContent=link wistia_embed popoverPreventScroll=true`} style={{display:'inline', position:'relative', ...style}} {...other}>
          <Button className={className}>{children}</Button>
        </span>
      :
      <div className={`wistia_responsive_padding wistia ${className || ''}`} style={{padding:'56.25% 0 0 0', position:'relative', ...style}} {...other}>
      <div className={`wistia_responsive_wrapper`} style={{height: '100%', left:0, position:'absolute',top:0, width:'100%'}}>
          <div className={`${classIn} playlistLinks=auto wistia_async_${id} videoFoam=false ${hideControls} ${quality ? 'qualityMax='+quality : ''}`} style={{height:'100%', position:'relative', width:'100%' }}>
            {
              videoType === 'Single' && <img src={`https://fast.wistia.com/embed/medias/${id}/swatch`} style={{filter:'blur(5px)',height:'100%',objectfit:'contain',width:'100%'}} alt="" aria-hidden="true"/>
            }
            {
              videoType === 'Channel' && <Loader className={`loader--dots`} aria-hidden="true"/>
            }
        </div>
      </div>
    </div> 
    }
  </> 
  )
}

Wistia.propTypes = {
  /**
   * Button content used for popover
   */
   children: PropTypes.any,
   className: PropTypes.string,
   /**
   * Toggles on and off controls
   */
   controls: PropTypes.bool,
   /**
   * Wistia Video ID
   */
   id: PropTypes.string,
   /**
   * Used to toggle rerender
   * TODO: see if there's a better way to toggle
   */
   isCurrent: PropTypes.bool,
  /**
   * Function to lift video state (ready, paused, playing)
   */
   passVideoState: PropTypes.func,
   /**
   * Set max wistia quality
   */
   quality: PropTypes.string,
   style: PropTypes.object,
  /**
   * Callback function for when video is done playing segment
   */
   videoCallBack: PropTypes.func,
  /**
   * Array of time codes in seconds. array[0] is the start time array [1] is the end.
   */
   videoSegment:PropTypes.arrayOf(PropTypes.number),
   /**
   * Video type to render
   */
   videoType: PropTypes.oneOf(['Single', 'PopOver', 'Channel', 'City', ''])
}

export default Wistia;






