import React, {lazy, Suspense} from "react";
import { LangProvider } from "../../src/context/LocaleContext";
import { ViewportProvider } from "../../src/context/ViewPort";
import Alerts from "./header/alertBar/alertBar";
import Header from "./header/header";
import PasswordProtect from "./passwordProtect/passwordProtect";
import Seo from "./seo/seo";
import SkipLink from "./skiplink/skiplink";

import "focus-visible";
import "../assets/sass/_index.scss";

const Modal = lazy(() => import("./../components/modal/modal"));
const Footer = lazy(() => import("./footer/footer"));

const Layout = ( {children, props } ) => {
  
  const { data, pageContext, location } = props?.props || {};
  const locale = pageContext?.locale;
  const pageData = typeof data === 'object' ?  data && Object.values(data)[0] : {};
  let {author, footerText, model, passwordProtected, seoMetaTags, title, slug, customSchema, hero, featuredImage } = pageData || {};
  let preloadImage = (hero && hero?.backgroundImage) || featuredImage;

  return (
      <LangProvider locale={locale} currentPath={pageContext.nonLocalePath} >
        <Seo preload={preloadImage?.gatsbyImageData?.images?.fallback?.src} passwordProtected={passwordProtected} customSchema={customSchema} data={seoMetaTags} nonlocalepath={pageContext.nonLocalePath} url={location.href} locale={locale} schemaType={pageContext.slug === '/' ? 'home' : model?.name} author={author} />
        <SkipLink data-addsearch="exclude" />

        {/* TODO: add translation */}
        <Alerts className="isIe11" data="<p><strong>Unsupported Browser!</strong> This website will offer limited functionality in this browser. We only support the recent versions of major browsers like Chrome, Firefox, Safari, and Edge.</p>" />
        <ViewportProvider>
          <Header slug={pageContext.slug} locale={locale} data-type={model?.name} headerCollapsed={model?.name === 'Page - Virtual City' || false} />
            <Suspense fallback="">
            <main id={`main`}>
              {
                passwordProtected ?
                <PasswordProtect slug={slug} title={title} data={passwordProtected}>{children}</PasswordProtect>
                :
                children
              }
            </main>
            <Modal />
            <Footer locale={locale} footertext={footerText || ''} />    
          </Suspense>
        </ViewportProvider>
      </LangProvider>
  );
};

export default Layout;