import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Chevron from '../../images/Chevron.inline.svg';
import ModalTrigger from '../../components/modal/modalTrigger/modalTrigger';
import Wistia from '../../components/wistiaVideo/wistiaVideo';
import Image from '../image/image';
import InternalLink from './internalLink.js/internalLink';
import IframeComponent from '../../modules/ProductDemo/IframeComponent';

/**
 * CMS Component: DatoCmsComponentLink
 * 
 * External Links
 * 
 * Internal Links
 * 
 * Internal Links + anchor
 * 
 * Link to asset
 * 
 * Modal
 * 
 * Image instead of button
 * 
 */

const Link = ({children, className, data, href, icon, linktag, locale, title, ...other}) => {
  let currentData = data?.link ? data.link[0] : data;
  let { displayText, downloadableAsset, externalUrl, id, internalLink, openInANewTab, slug, wistiaId,iframeSrc, ...otherLinkAttr} = currentData ? currentData[0] || currentData : {};

  //default to external link
  let LinkTag = linktag || 'a';
  let content = children ? children : ( data?.image ? <Image data={data.image} decorative={false} /> : displayText );
 
  //Build out attr for link
  const linkSettings = {
    'target': openInANewTab ? "_blank" : "_self",
    'aria-describedby': openInANewTab ? "new-window-warning" : "",
    'className' : `link ${icon ? 'icon': ''} ${data?.image ? 'imageLink' : (className || '')}`,
    ...otherLinkAttr
  }

    //handle direct hrefs
    if(href){
      if(href.startsWith("/")){
        linkSettings.linkType = 'internalPath';
        linkSettings.linkTarget = href;
        LinkTag = InternalLink;
      }else if(href.startsWith("#")){
        linkSettings.href = href;
      }else{
        linkSettings.href = href;
        linkSettings.rel = "noopener external";
      }
    }
    else if(wistiaId){
      LinkTag = Wistia;
      linkSettings.id = wistiaId;
      linkSettings.videoType = 'PopOver';

    }
    else if (iframeSrc) {
      return <IframeComponent
      data={{ displayText: displayText }}
      scriptSrc="https://js.storylane.io/js/v1/storylane.js"
      iframeSrc={iframeSrc}
    />;
    }
    //handle data
    else if(downloadableAsset){
      linkSettings.href = downloadableAsset.url;
      linkSettings.target = "_blank";
    }else if(internalLink?.__typename === 'DatoCmsComponentModal'){
      LinkTag = ModalTrigger;
      linkSettings.id = id;
      linkSettings.data = internalLink?.content;
    }else if(internalLink){
      linkSettings.linkType = internalLink?.__typename;
      linkSettings.linkTarget = internalLink;
      linkSettings.external = externalUrl;
      //attempt to fallback to title
      if(!content){
        content = internalLink?.title;
      }
      LinkTag = InternalLink;
    }else if(externalUrl){
      if(externalUrl.startsWith('/')){
        linkSettings.linkType = 'internalPath';
        LinkTag = InternalLink;
        linkSettings.linkTarget = externalUrl;
      }else{
        linkSettings.href = externalUrl;
        linkSettings.rel = "noopener external";
      }
    }else if(slug){
      linkSettings.linkType = 'internalSlug';
      linkSettings.linkTarget = {};
      linkSettings.linkTarget.slug = slug;
      LinkTag = InternalLink;
    }else{
      LinkTag = 'span';
    }

  return (
    content ?
    <LinkTag linktag={linktag} title={typeof content === 'string' ? content : ''} {...linkSettings} {...other}>
      {content}
      {
        icon && 
        <span className="chevron" aria-hidden="true">
          <Chevron />
        </span>
      }
    </LinkTag>
    :
    false
  )
}

export default Link;

Link.propTypes = {
  /**
   * Any Content eg icons or clickable components
   */
   children : PropTypes.any,
  /**
   * Link Class Name
   */
  className : PropTypes.string,
  /**
   * Cms Object OR slug provided
   */
  data: PropTypes.oneOfType([
    PropTypes.shape({
      displayText : PropTypes.string,
      downloadableAsset: PropTypes.object,
      externalUrl: PropTypes.string,
      id: PropTypes.string,
      internalLink: PropTypes.object,
      openInANewTab : PropTypes.bool,
      link : PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
      slug: PropTypes.string
    }),
    PropTypes.array
  ]),
  /**
   * Include chevron icon
   */
  icon : PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]),
};

Link.defaultProps = {
  className : "link--text--primary",
  icon: true,
  locale: 'en'
};

export const LinkFragment = graphql`
  fragment LinkFragment on DatoCmsComponentLink {
     
    id: originalId
    displayText
    downloadableAsset{
      ...ImageFragmentSrc
    }
    externalUrl
 
    internalLink {
      __typename
      ... on Node {
        ...ModalFragment

        ... on DatoCmsPage{
          slug
          title
        }

        ... on DatoCmsPageCalculator{
          slug
          title
        }

        ... on DatoCmsPageVirtualCity{
          slug
          title
        }

        ... on DatoCmsPageArchive{
          slug
          title
        }

        ... on DatoCmsPageArchiveBasic{
          slug
          title
        }

        ... on DatoCmsPosttypeNews{
          slug
          title
        }

        ... on DatoCmsPosttypeProduct{
          slug
          title
        }

        ... on DatoCmsPosttypeIndustry{
          slug
          title
        }

        ... on DatoCmsPosttypeCaseStudy{
          slug
          title
        }

      }
    }
  openInANewTab
  wistiaId
  iframeSrc
}
`;

export const ImageLinkFragment = graphql`
fragment ImageLinkFragment on DatoCmsComponentImageLink {
   
  id: originalId
  image{
    ...HalfImageFragment
  }
  downloadableAsset{
    ...ImageFragmentSrc
  }
  externalUrl

  internalLink {
    __typename
    ... on Node {
      ...ModalFragment
      ... on DatoCmsPage{
        slug
        title
      }

      ... on DatoCmsPageCalculator{
        slug
        title
      }
      
      ... on DatoCmsPageVirtualCity{
        slug
        title
      }
      ... on DatoCmsPageArchive{
        slug
        title
      }

      ... on DatoCmsPageArchiveBasic{
        slug
        title
      }
      
      ... on DatoCmsPosttypeNews{
        slug
        title
      }
      ... on DatoCmsPosttypeProduct{
        slug
        title
      }
      ... on DatoCmsPosttypeIndustry{
        slug
        title
      }
      ... on DatoCmsPosttypeCaseStudy{
        slug
        title
      }
    }
  }
openInANewTab
}
`;