module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"75F","description":"The World's Most Advanced IoT Building Automation","lang":"en","display":"standalone","start_url":"/","cache_busting_mode":"none","icon":"src/images/75Fpwa.png","background_color":"#fff","theme_color":"#e24301","icons":[{"src":"src/images/maskablePWALogo.png","sizes":"512x512","type":"image/png","purpose":"any maskable"}],"localize":[{"start_url":"/en-in/","lang":"en","name":"75F","description":"The World's Most Advanced IoT Building Automation"}],"legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
