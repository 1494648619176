import React, { useState, useContext } from 'react';
import Image from '../../components/image/image';
import HtmlBlock from '../../components/htmlBlock/htmlBlock';
import {passwordProtected, passwordProtected__container, passwordProtected__form, passwordProtected__form__error} from './passwordProtect.module.scss';
import Button from '../../components/button/button';
import Cookies from 'universal-cookie';
import { LangContext } from '../../context/LocaleContext';

/**
 * Password protected page
 * 
 * @component
 * @category Global-Modules
 * @summary used to protect a page route with a password handled inside contentful page options
 * page context stores any password protected pages along with the one password that unlocks them all
 * @param {*} children the page object sitting behind the password protection
 */
const Password = ({ data, children, title : pageTitle }) => {
  const cookies = new Cookies();

  const {password : key, backgroundImage, copy, title} = data;

    const { getTranslation } = useContext(LangContext);

    const [error, setError] = useState(null);
    const [protectedPage, setProtectedPage] = useState(cookies.get(title) ? cookies.get(title) !== 'granted' : true);

    const checkResults = (e) => {
      e.preventDefault();
      if(key === e.target.password.value) {
        setError(false);
        setProtectedPage(false);
        cookies.set(title, 'granted', { path: '/' });
      } else {
          setError(true);
      };
    };

    return (
      protectedPage  
      ?
      <div className={passwordProtected}>
        {
          backgroundImage && <Image className={`backgroundMedia`} data={backgroundImage} />
        }
        <div className={`container narrowContent ${passwordProtected__container}`}>
          {
            pageTitle && <h1>{pageTitle}</h1>
          }
          {
            copy && <HtmlBlock data={copy}/>
          }
          <form className={`${passwordProtected__form} ${error ? 'error' : ''}`} onSubmit={(e)=>checkResults(e)}>
            <div className="protected-module__form__password">
              <label className={`sr_only`} htmlFor="password">{getTranslation('Password')}</label>
                <input 
                  autoComplete="off" 
                  id="password" 
                  type={"password"} 
                />
              {error && <span className={passwordProtected__form__error}>{getTranslation('PasswordIncorrect')}</span>}
            </div>
            <Button type="submit" className={`btn--primary`}>{getTranslation('Submit')}</Button>
          </form>
        </div>
      </div>
      :
      children
    );
};

export default Password;
