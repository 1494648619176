import React from 'react';
import PropTypes from 'prop-types';
import Chevron from '../../images/Chevron.inline.svg';

/**
 *
 * Buttons for user interaction
 * 
 */

const Button = ({ children, className, icon, onClick, ...other }) => {
  return (
    <button       
      title={typeof children === 'string' ? children : ''} 
      className={`${className || ''} ${icon && 'icon'} button`} 
      onClick={onClick} 
      {...other} 
      >
      {children}
      {
        icon && 
        <span className="chevron" aria-hidden="true">
          <Chevron />
        </span>
      }
    </button>
  )
}

Button.propTypes = {
  /**
  * Any Content eg icons, text, components
  */
  children : PropTypes.any,
  /**
  * Class Name for the button
  */
  className : PropTypes.string,
  /**
  * Display Chevron Icon
  */
  icon : PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]),
  /**
  * Click Handler
  */
  onClick : PropTypes.func,
};

Button.defaultProps = {
  className : "btn--primary",
  icon: true
};


export default Button;
