import React, {useContext} from 'react';
import Link from '../../components/link/link'
import { LangContext } from '../../context/LocaleContext';
/**
 * Skip Link 
 * 
 * @component
 * @category Global-Modules
 * @subcategory Layout
 * @summary Component that adds a screen reader only skip link past the main nav on each page
 */
const SkipLink = ({...other}) => {
    const { getTranslation } = useContext(LangContext);
    return (
      <div role="navigation" {...other}>
        <Link href="#main" className="skip-link btn--primary--sm">{getTranslation("SkipToContent")}</Link>
      </div>
    );
};

export default SkipLink;
