import React from 'react';
import PropTypes from 'prop-types';
import Link from '../link/link';

/**
 * Converts Array if Link objects into Nav syntax
 * 
 */
const NavigationBlock = ({data, icon, linkclass, linktag, ...other}) => {
  return (
      data?.length > 1 ?
      <nav {...other}>
        <ul>
          {
            data.map((item, i) => (
              <li key={i}>
                {item.image ?
                  <Link icon={false} data={item} linktag={linktag}/>
                  :
                  <Link icon={icon} className={linkclass} data={item} linktag={linktag}/>
                }
              </li>
            ))
          }
        </ul>
      </nav>
      :
      data && <Link data={data[0]} icon={data[0].image ? false : icon} className={linkclass} linktag={linktag} />
  );
};

NavigationBlock.propTypes = {
  /**
   * Array of contentful link objects
   */
  data: PropTypes.array,
  /**
   * If links should include chevron
   */
  icon: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]),
  /**
   * Class passed directly to links
   */
  linkclass: PropTypes.string
}

export default NavigationBlock;