import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import Button from '../../../components/button/button';
import Search from '../../../images/search.inline.svg';
import SearchForm from '../../searchForm/searchForm';
import useOnClickOutside from '../../../lib/hooks/useOnClickOutside';
import {header__searchbar, header__searchbarMobile} from './headerSearch.module.scss';
/**
 * SearchBar
 * 
 */
const HeaderSearch = ({collapsed, onclick}) => {
    const [searchOpen, setSearchOpen] = useState(false);
    const searchBar = useRef(null);

    useOnClickOutside(searchBar, () => setSearchOpen(false));

    return (
      <div ref={searchBar}>
        {
          collapsed && <Button className={header__searchbarMobile} icon={false} onClick={()=>onclick(false)}><Search /></Button>  
        }
        <SearchForm className={header__searchbar} placeholder={`What can we help you find?`} openstate={searchOpen}>
          <Button aria-label={searchOpen === true ? "Open search bar" : "Search"} type={searchOpen === true ? "button" : "submit"} onClick={()=>setSearchOpen(!searchOpen)} icon={false} className={`search__button ${searchOpen === true ? 'active' : ''}`}>
            <Search />
          </Button>
        </SearchForm>
      </div>
    );
};

HeaderSearch.propTypes = {
  /**
  * Searchbar collapsed state
  */
  collpased: PropTypes.bool,
  /**
  * Pass onclick function to toggle search bar for mobile
  */
  onclick: PropTypes.func
}

export default HeaderSearch;