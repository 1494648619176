import React, {useState} from 'react';
import PropTypes from 'prop-types';
import HtmlBlock from '../../../components/htmlBlock/htmlBlock';
import {alertBar, alertBar__close, alertBar__container, alertBar__content} from './alertBar.module.scss';
import Close from '../../../images/CloseSymbolThin.inline.svg';
import Button from '../../../components/button/button';
import Cookies from 'universal-cookie';

const Alerts = ({className, data}) => {
  const cookies = new Cookies();
  const [isOpen, setIsOpen] = useState(cookies.get('banner') ? cookies.get('banner') !== 'closed' : true);

  const closeBanner = () => {
    setIsOpen(false);
    cookies.set('banner', 'closed', { path: '/' });
  }

  return (
    isOpen &&
    <div role={`alert`} className={`${alertBar} ${className}`}>
      <div className={`${alertBar__container}`}>
        <Button icon={false} onClick={closeBanner} className={alertBar__close} ><Close/></Button>
        <HtmlBlock data={data} className={alertBar__content}/>
      </div>
    </div>
  )
}

Alerts.propTypes = {
   /**
   * optional classname
   */
  className: PropTypes.string,
  /**
   * Translation key for locale context
   */
  translation: PropTypes.string
}

export default Alerts
