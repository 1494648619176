/**
 * 
 * 
 * @category Renders
 * @summary Renders the correct Slide types to slider
 * @return {array} Returns the correct Slide components
 * 
 */
//type: home, 
const buildSchema = ({type, seoData, url, siteTitle, logo, ...other}) => {
  //convert seoData to object
  let metaData = seoData.tags.reduce((obj, tag) => ({
    ...obj,
    [tag?.attributes?.property || tag?.attributes?.name]: tag?.attributes?.content
  }), {});

  switch(type){
    case 'home':
      return url ? {
        "@context": "https://schema.org/",
        "@type": "WebSite",
        "name": siteTitle,
        "url": url,
        "potentialAction": {
          "@type": "SearchAction",
          "target": `${url}search/?addsearch={search_term_string}`,
          "query-input": "required name=search_term_string"
        }
      } : false;
    case 'Post Type - News':
      return url ? {
        "@context": "https://schema.org",
        "@type": "NewsArticle",
        "mainEntityOfPage": {
          "@type": "WebPage",
          "@id": url
        },
        "headline": metaData['og:title'],
        "description": metaData['description'],
        "image": metaData['og:image'],  
        "author": {
          "@type": "Person",
          "name": other?.author
        },  
        "publisher": {
          "@type": "Organization",
          "name": siteTitle,
          "logo": {
            "@type": "ImageObject",
            "url": logo || ''
          }
        },
        "datePublished": metaData['article:published_time'],
        "dateModified": metaData['article:modified_time']
      } : false;
    default:
      return {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": metaData['og:title'],
        "description": metaData['description'],
        "publisher": {
          "@type": "Organization",
          "name": siteTitle
        }
    };
  }
};

module.exports = {buildSchema};


