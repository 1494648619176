import React, { useState } from "react";
import PropTypes from "prop-types";
import Chevron from '../../images/Chevron.inline.svg';
import Button from "../../components/button/button";
import "../ProductDemo/IframeComponent.scss";

const IframeComponent = ({ children, className, data, href, icon, linktag, locale, title, scriptSrc, iframeSrc, ...other }) => {
  const [iframeVisible, setIframeVisible] = useState(false);

  let { displayText } = data || {};

  const toggleIframe = () => {
    setIframeVisible(!iframeVisible);
  };

  return (
    <div>
      <button className={`iframe-link`} onClick={toggleIframe}>
      {displayText}
      {(
        <span className="chevron" aria-hidden="true">
          <Chevron />
        </span>
      )}
    </button>
      {iframeVisible && (
        <div className="iframe-overlay">
          <div className="iframe-container">
            <button
              className="close-button"
              onClick={toggleIframe}
              aria-label="Close"
            >
              &times;
            </button>
            <iframe
              src={iframeSrc}
              width="100%" // Adjust the width as needed
              height="100%" // Adjust the height as needed
              allow="fullscreen; camera; microphone"
              style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", border: "none" }}
            ></iframe>
          </div>
        </div>
      )}
      <script src={scriptSrc}></script>
    </div>
  );
};

export default IframeComponent;
