import React, {useState, createContext} from 'react';
const ModalContext = createContext();

/**
 * Modal Context acts a store to house global Modal States
 * 
 * @category Context
 * @summary used to house utility functions and global state for the modal.
 * To use, import { useContext } from 'react, also import { PageContext } from this location.
 * Then declare any function or state needed here such as in example below;
 */

const ModalProvider = ({children}) => {
  const [modalData, setModalData ] = useState(null);
  const [modalClasses, setModalClasses ] = useState(null);
  const [modalTriggerId, setModalTriggerId] = useState(null);

  /**
   * Toggles the global Modal
   * @param {object} data modal data
   * @param {string} focusId id of the button that toggled the modal for refocus
   */
  const toggleModal = (data, focusId, classes) => {
    //if modalData exists, close the modal and refocus the id that triggered the modal
    if(modalData){
      setModalData(false);
      if(document && modalTriggerId){
        document.getElementById(modalTriggerId).focus();
      }
    }else{
      setModalData(data);
      setModalTriggerId(focusId);
      setModalClasses(classes);
    }
    return;
  }

  return (
    <ModalContext.Provider value={{ 
      toggleModal,
        modalData,
        modalClasses        
      }}>
      { children }
    </ModalContext.Provider>
  );
}

export { ModalProvider, ModalContext }
