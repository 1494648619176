import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import ContentBlock from '../../../components/contentBlock/contentBlock';
import ExpandPanel from '../../../components/expandPanel/expandPanel';
import NavigationBlock from '../../../components/navigationBlock/navigationBlock';
import SubItem from './subItem/subItem';

/**
 * MegaMenuItem
 * 
 */
const MegaMenuItem = ({context, data, isopen, uniqueid, slug, ...other}) => {
  const {col1Cta, col2Content, col3Links, linkName} = data;
  /**
   * function to lift menu panel state
   * If menu is open, and parent closes. Close menu
   */
  const parentHandler = (state) => {
    if(isopen && !state){
      isopen(false);
    }
  }

  /**
   * function to lift submenu panel state
   * Pass child state up unless null
   * if null leave it at the current state (null indicates a sibling has been clicked)
   */
  const childHandler = (state) => {
    if(state !== null){
      isopen(state);
    }
  }

  return (
    <ExpandPanel {...other} hasNavigated={slug} currentstate={parentHandler} uniqueid={uniqueid} panelClassName="megaMenuItem" buttonContent={linkName} icon={true}>             
      <div className="container">
        <ContentBlock className="megaMenuItem__col1" data={col1Cta[0]} linkclass="link--featured--primary"/>
        <div className="megaMenuItem__col2" colcount={col2Content.length}>
          {
            col2Content?.map((item, i)=>
              <SubItem key={i} data={item} currentstate={childHandler} uniqueid={`col2-${uniqueid}-${i}`} linkclass="link--text--primary" />
            )
          }
        </div>
        <NavigationBlock className="megaMenuItem__col3" data={col3Links} linkclass="link--featured--primary"/>
      </div>
      <div className="header__bar"></div>
    </ExpandPanel>
  );
};

MegaMenuItem.propTypes = {
  /**
   * CMS Object
   */
  data: PropTypes.shape({
    col1Cta: PropTypes.array,
    col2Content: PropTypes.array,
    col3Links: PropTypes.array,
    linkName: PropTypes.string
  }),
  /**
   * Function to lift state
   */
  isopen: PropTypes.func,
  /**
   * ID for panel tags
   */
  uniqueid : PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

export default MegaMenuItem;

export const MegaMenuItemFragment = graphql`
fragment MegaMenuItemFragment on DatoCmsComponentMegaMenuLink {
  col1Cta{
    ...HeadingCopyLinkFragment
  }
  col2Content{
    __typename
    ...HeadingCopyLinkFragment
    ...LinkCollectionFeaturedFragment
    ...ImageLinkFragment
  }
  col3Links{
    ...LinkFragment
  }
  linkName
}`;

 