import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {LangContext} from '../../context/LocaleContext';

/**
 * SearchForm
 * 
 */
const SearchForm  = ({children, className, placeholder, openstate, ...other}) => {
  const { linkPrepend, getTranslation } = useContext(LangContext);
  let content = children ? children :  <button aria-label="Search" type={`submit`}>{getTranslation("Submit")}</button>;
  
  return (
    <form action={`${linkPrepend}/search`} className={className || ''} {...other}>
      <input ref={input => (input && openstate === true) && input.focus()} className={openstate ? '' : 'displayNone'} type="text" id="searchbar" name="addsearch" aria-label={"Search"} placeholder={placeholder}/>
      {content}
    </form>
  );
};

SearchForm.propTypes = {
  /**
  * Submit button 
  */
  children : PropTypes.element,
  /**
  * Optional: toggle state for form
  */
  openstate : PropTypes.bool,
  /**
  * Input placeholder
  */
   placeholder : PropTypes.string
}

SearchForm.defaultProps = {
  openstate : true,
  placeholder: 'What can we help you find?'
}

export default SearchForm;
