import React, {useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import Button from '../button/button';
import {generateSlug} from '../../lib/functions/util-functions';
import useOnClickOutside from '../../lib/hooks/useOnClickOutside';

/**
 * Accordion / Panel toggle functionality
 * 
 */
const ExpandPanel = ({buttonContent, children, closeOutsideClick, currentstate, panelClassName, startOpen, uniqueid, hasNavigated, ...other}) => {
  const [expandPanelOpen, setexpandPanelOpen] = useState(startOpen);
  const node = useRef(null);

  //Create unique panel id
  const linkId = (typeof buttonContent === 'string' ? generateSlug(buttonContent)+'-' : '')  + uniqueid;

  /**
   * Function runs on outside click to close the panel
   */
  const handleClick = () => {
    if(expandPanelOpen && closeOutsideClick){
      setexpandPanelOpen(false);
      if(currentstate){
        currentstate(null);
      }
    }
  };

  useOnClickOutside(node, () => handleClick(null));

  useEffect(() => {
    setexpandPanelOpen(startOpen)
  }, [hasNavigated, startOpen])

  return (
    <div ref={node} className={`panel ${panelClassName} ${expandPanelOpen ? 'active' : ''} `}>
      <Button {...other} 
        id={`${linkId}-controls`} 
        aria-controls={`${linkId}`} 
        active={`${expandPanelOpen}`}
        onClick={()=>{
          setexpandPanelOpen(!expandPanelOpen);
          if(currentstate){currentstate(!expandPanelOpen);
          }}}
      >
        {buttonContent}
      </Button>
      {
        <div className={`${panelClassName+'__expandPanel'}`} id={`${linkId}`} aria-labelledby={`${linkId}-controls`}>
          {                    
            children
          }
        </div>
      }
    </div>
  );
};

ExpandPanel.propTypes = {
  /**
  * Trigger button content
  */
  buttonContent : PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  /**
   * Content for the Panel
   */
  children: PropTypes.any.isRequired,
  /**
   * True if panel closes on outside click
   */
  closeOutsideClick: PropTypes.bool,
  /**
   * Function to hoist current state (returns null if closed via click outside)
   */
  currentstate: PropTypes.func,
  /**
   * Class Name for panel component
   */
   panelClassName : PropTypes.string,
  /**
   * If panel starts open
   */
  startOpen : PropTypes.bool,
  uniqueid : PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
 };

 ExpandPanel.defaultProps = {
  closeOutsideClick : true,
  panelClassName : 'expandPanel',
  startOpen: false,
};

export default ExpandPanel;
