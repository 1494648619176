import React, {lazy, Suspense} from 'react';
import Render from './render';

const ContentBlock = lazy(() => import("../../components/contentBlock/contentBlock"));
const LinkCollection = lazy(() => import("../../components/linkCollection/linkCollection"));
const Link = lazy(() => import("../../components/link/link"));

/**
 * 
 * 
 * @category Renders
 * @summary Renders the navigation components to globals
 * @return {array} navigation components
 * 
 */
const RenderNav = ({data, ...other}) => {

  const map = {
    'DatoCmsComponentHeadingCopyLink' : ContentBlock,
    'DatoCmsComponentLinkCollection' : LinkCollection,
    'DatoCmsComponentLinkCollectionFeatured' : LinkCollection,
    'DatoCmsComponentImageLink' : Link,
  };

  return (
    <Suspense fallback="">
      <Render data={data} map={map} {...other} />
    </Suspense>
  ) 
};

export default RenderNav;


