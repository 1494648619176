import React, { createContext } from 'react';
import { navigate, useStaticQuery, graphql } from "gatsby"
import { defaultLanguage, languageObjectArray } from '../lib/maps/languageMap';
import Cookies from 'universal-cookie';


const LangContext = createContext({});

const LangProvider = ({ locale, currentPath, children}) => {
  const cookies = new Cookies();
  const {allDatoCmsTranslation} = useStaticQuery(
    graphql`
      query {
        allDatoCmsTranslation {
          nodes{ 
            locale
            translationJson
          }
        }
      }`
    );

  const translationarray = allDatoCmsTranslation?.nodes;

  let languageLowerC = locale.toLowerCase();

  const currentLanguage = locale;
  const linkPrepend = languageLowerC === defaultLanguage ? '' : '/' + languageLowerC;
  const currentFullPath = `${currentLanguage !== defaultLanguage ? '/' + locale.toLowerCase() : ''}${currentPath !== '/' ? currentPath : currentPath}`;

  //translations are set in Dato as JSON
  //This function gets the correct translation per current locale.
  const getTranslation = (key) => {
    let results = JSON.parse(translationarray?.find((translate)=>translate.locale === locale)?.translationJson)[key] || JSON.parse(translationarray?.find((translate)=>translate.locale === defaultLanguage)?.translationJson)[key];
    return results || key;
  }

  const changeLanguage = (locale) => {
    cookies.set('nf_country', languageObjectArray.find((language)=>language.cmsLocale === locale).countryEx, { path: '/' });
    return navigate(`${locale !== defaultLanguage ? '/' + locale.toLowerCase() : ''}${currentPath}`);
  }

  return <LangContext.Provider value={{getTranslation, currentLanguage, changeLanguage, linkPrepend, currentFullPath}}>{children}</LangContext.Provider>;
};

export {LangProvider, LangContext};
