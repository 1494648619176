import React, {createContext, useState, useLayoutEffect, startTransition} from "react";
import {BreakPtMobile, BreakPtDesktop} from '../assets/sass/export.module.scss';
const ViewportContext = createContext({});


const ViewportProvider = ({ children }) => {
  const getSize = (currentWidth) => {
    if(currentWidth < BreakPtMobile){
      return 'mobile';
    }else if(currentWidth > BreakPtDesktop){
      return 'desktop';
    }else{
      return 'tablet';
    }
  }

  //mobile, tablet, desktop
  const [screenSize, setScreenSize] = useState(false);

  useLayoutEffect(() => {

    const handleWindowResize = () => {
      startTransition(() => {
        setScreenSize(getSize(typeof window !== 'undefined' && window.innerWidth));
      });
    };

    if(typeof window !== 'undefined'){
      handleWindowResize();
    }

    window?.addEventListener("resize", handleWindowResize, {passive: true});
    return () => window?.removeEventListener("resize", handleWindowResize, {passive: true});
  }, []);

  return (
    <ViewportContext.Provider value={{ screenSize }}>
      {children}
    </ViewportContext.Provider>
  );
};

export { ViewportProvider, ViewportContext }

