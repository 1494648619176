import React, {useRef, useState, useEffect, startTransition} from 'react';
import { useStaticQuery, graphql } from "gatsby";
import { globalHistory } from '@reach/router';
import PropTypes from 'prop-types';
import {DividerHeight, LogoWidthDesktop, LogoHeightDesktop} from '../../assets/sass/export.module.scss';
import {useScrollPosition} from '../../lib/hooks/useScrollPosition';
import useOnClickOutside from '../../lib/hooks/useOnClickOutside';

import AlertBar from './alertBar/alertBar';
import Button from '../../components/button/button';
import HeaderSearch from './headerSearch/headerSearch';
import Image from '../../components/image/image';
import Link from '../../components/link/link';
import MegaMenuItem from './megaMenuItem/megaMenuItem';
import RenderNav from "../../lib/renders/renderNav";
import SelectLanguage from './languageSelect/languageSelect';

/**
 * Header
 * 
 */
const Header = ({headerCollapsed, locale, slug, ...other}) => {
  const [openHeader, setOpenHeader] = useState(headerCollapsed || false);
  const [openMobileHeader, setMobileHeader] = useState(false);
  const [openMobileSubHeader, setMobileSubHeader] = useState(false);
  const [stickyMenu, setStickyMenu] = useState(false);

  const headerRef = useRef(null);

  useOnClickOutside(headerRef, () => setOpenHeader(headerCollapsed || openHeader ? true : false));

  useScrollPosition(function setScrollPosition ({ currentPosition }) {
    setStickyMenu(currentPosition.y > (document.querySelector('header').offsetTop * -1) ? false : true );
    if(headerCollapsed !== true){       
      //collapse header when screen position is past the height of the header  
      setOpenHeader(currentPosition.y > ((document.querySelector('header').offsetTop + document.querySelector('header').offsetHeight - parseInt(DividerHeight)) * -1) ? false : true);
    }
  });

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      startTransition(() => {
        if (action === 'PUSH') {
          setMobileHeader(false);
          setMobileSubHeader(false);
        }
      })
    })
  }, [setMobileHeader, setMobileSubHeader])



  const { allDatoCmsGlobalHeader : header } = useStaticQuery(
    graphql`
    query {
      allDatoCmsGlobalHeader {
        nodes{
          announcement
          headerCta{
            ...LinkFragment
          }
          locale
          logo{
            ...LogoFragment
          } 
          megaMenuLinks{
            ...MegaMenuItemFragment
          }
          topBarLinks{
            __typename
            ...LinkCollectionFragment                   
          }
          topBarLinksMobile{
            __typename
            ...LinkCollectionFragment                   
          }
        }
      }
    }`
  );

  const {announcement, headerCta, logo, megaMenuLinks, topBarLinks, topBarLinksMobile} = header.nodes.filter(item => item.locale === locale)[0] || {};

  return (
    <>
      {
        announcement && <AlertBar data={announcement}/>
      }
      <header ref={headerRef} data-collapsed={headerCollapsed} className={`header ${openMobileSubHeader ? 'openMobileSubheader' : ''} ${openHeader ? 'toggleBar' : ''}`} {...other}>
        
        <div className={ `header__mobile mobile ${openMobileHeader ? 'mobileOpen': ''}`}>
          <div className="container">
            {
              logo && 
              <Link data={{slug: '/'}} className="logo" icon={false} aria-label="Home">
                <Image alt="75F logo" data={logo}  width={98} height={35} lazyload={false} />
              </Link>
            }
            <Button aria-label={`${openMobileHeader ?  'Close' : 'Open'} mobile menu`} className={`hamburger ${stickyMenu ? 'hamburger--sticky' : ''}`} icon={false} onClick={()=>setMobileHeader(!openMobileHeader)}><span></span></Button>
          </div>
        </div>

        <div className={`header__wrapper`}>
          <div className="header__topbar">
            <div className="container">
              <HeaderSearch collapsed={openMobileSubHeader} onclick={setMobileSubHeader} />
              <SelectLanguage locale={locale} className="mobile"/>
              <RenderNav data={topBarLinks} type="dropdown" className="header__topbar__links tablet-min" uniqueid="header-desktop" icon={false}/>
              <RenderNav data={topBarLinksMobile} type="dropdown" className="header__topbar__links mobile" uniqueid="header-mobile" icon={false}/>
            </div>
          </div>
          <div className="header__main">
            <div className="container">
              <div className="header__main__left">
                {
                  logo && 
                  <Link data={{slug: '/'}} className="logo tablet-min" icon={false} aria-label="Home">
                    <Image alt="75F logo" data={logo} height={LogoHeightDesktop} width={LogoWidthDesktop} lazyload={false} />
                  </Link>
                }
                <ul className="header__main__left__links">
                {
                  megaMenuLinks?.map((item, i) => (
                    <li key={i}><MegaMenuItem slug={slug} isopen={setMobileSubHeader} uniqueid={i} data={item} icon={false} className="header__left__links header-link" /></li>
                  ))
                }
                </ul>
              </div>
              <div className="header__main__right">
                <Link data={headerCta} className="header-link--featured" />
                <SelectLanguage locale={locale} className="tablet-min" />
              </div>
            </div>
          </div>
          <div className={`header__bar tablet-min`}>
            {
              openHeader && 
              <Button className="header__bar__btn" aria-label="View Header" onClick={()=>setOpenHeader(true)} onKeyPress={(event)=>{if(event.key === 'Enter'){setOpenHeader(false)}}}/>
            }
          </div>
        </div>
      </header>
    </>
  );
};

Header.propTypes = {
  /**
   * If header is initially collapsed
   */
   headerCollapsed: PropTypes.bool,
  /**
   * Current locale
   */
  locale: PropTypes.string.isRequired
}

export default Header;
