import { graphql, useStaticQuery } from "gatsby"

//https://stackoverflow.com/questions/66538038/get-array-of-all-existing-page-routes-in-gatsby
const useInternalPaths = () => {
  const {pages} = useStaticQuery(
    graphql`
      query {
        pages: allSitePage {
          nodes {
            path
            pageContext
          }
        }
      }
    `) || {};
  return pages?.nodes;
}


export default useInternalPaths

