/**
 *  Convert a string to Kabob case
 */
const generateSlug = (str) =>
{ 
  if(str){
    str = str.replace(/^\s+|\s+$/g, '');
    // Make the string lowercase
    str = str.toLowerCase();
    // Remove accents, swap ñ for n, etc
    var from = "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;";
    var to   = "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------";
    for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    // Remove invalid chars
    str = str.replace(/[^a-z0-9 -]/g, '') 
    // Collapse whitespace and replace by -
    .replace(/\s+/g, '-') 
    // Collapse dashes
    .replace(/-+/g, '-'); 
  }
  return str;
}

/**
 *  Returns a filtered array
 * 
 * @param {array} arrayIn - Array to filter
 * @param {string} key - object key of the term(s)
 * @param {string|array} terms - Terms to filter by
 * @param {string} operator - AND | OR
 * @param {string} subKey - if terms are nested, include subkey
 * 
 */

//  catMatch(allPosts, 'categories', cats, 'OR', 'slug')
const catMatch = (arrayIn, key, terms, operator, subKey) => {
  let filteredArray = arrayIn;

  if(typeof terms === 'string'){
    terms = [terms];
  }

  const getSubArray = (item, key, subKey) =>{
    let itemReturn = item[key] || [];
    if(subKey && itemReturn){
      itemReturn = itemReturn.map((i)=>i[subKey]);

    }
    return itemReturn;
  }
  //Set operator functions
  let operatorAND = (arr, target) => target.every(v => arr.includes(v)) && arr.length === target.length;
  let operatorOR = (arr, target) => target.some(v => arr.includes(v));


  if(terms.length > 1 && filteredArray.length > 0){
      filteredArray = filteredArray.filter(
        v => operatorAND( terms, getSubArray(v, key, subKey))
      );
    }else{
      filteredArray = filteredArray.filter(
        v => operatorOR( terms, getSubArray(v, key, subKey))
      );
    }

  return filteredArray.length > 0 ? filteredArray : false;
};

const structuredTextNotEmpty = (content) => {
  return content?.blocks ? (content?.blocks.length > 0 || content?.links.length > 0 || content?.value?.document?.children.length > 1 || content?.value?.document?.children[0].children[0].value !== '' || false) : false;
}

const ConditionalWrapper = ({ condition, wrapper, children }) => {
  return condition ? wrapper(children) : children;
}


module.exports = {generateSlug, catMatch, structuredTextNotEmpty, ConditionalWrapper};
