import React, {useContext} from 'react';
import {Link as GatsbyLink } from 'gatsby';
import PropTypes from 'prop-types';
import {LangContext} from '../../../context/LocaleContext';
import useInternalPaths from '../../../lib/hooks/useInternalPaths';


const InternalLink = ({children, external, linktag, linkTarget, linkType, ...other}) => {
  const InternalPaths = useInternalPaths() || {};
  const { currentLanguage, linkPrepend, currentFullPath } = useContext(LangContext);

  let linkTo;
  let Link = linktag || GatsbyLink;

  const isAnchor = (link) => {
    if(link){
      return (link?.startsWith('/#') || link?.startsWith('#') || link?.startsWith('?'));
    }
    return;
  }

  if(linkType){
    switch(linkType){
      case 'internalPath':
      linkTo = linkPrepend + linkTarget;
      break;
      default:
        if(linkTarget.slug === 'home'){
          linkTarget.slug = '/';
        }
        //use gatsbylink for internal navigation only
        //attempt to match the slug with an existing path
        let pathMatch = InternalPaths?.filter(item =>((item.pageContext.slug === linkTarget.slug))) || [];
        //if multiple locales exist
        if(pathMatch && pathMatch.length > 1){
          linkTo = pathMatch.find(item =>((item.pageContext.locale === currentLanguage))).path; 
        }else{
          linkTo = pathMatch.length > 0 ? linkPrepend + pathMatch[0].pageContext.nonLocalePath : null;
        }

        if(linkTo === null){
          console.warn('not found ', linkTarget.slug, currentLanguage);
        }

        //if external url is an anchor, append it to found path
        if(external){
          if(isAnchor(external)){
            linkTo = linkTo.replace(/\/$/, '') + external.replace(/^\//, '');;
          }
        }
        break;
    }
  }

  if(!linkTo || !children){
    console.warn('link not rendering', linkTarget, children, linkTo);
  }

  return (

    ( linkTo || children ) ?
    <Link aria-current={currentFullPath === linkTo ? 'page': 'false'} to={linkTo} {...other}>
      {children}
    </Link>  
    :
    false
  )
}

export default InternalLink;

InternalLink.propTypes = {
  /**
   * internal link display content
   */
   children : PropTypes.any,
  /**
   * anchors or url params to append to link
   */
   external: PropTypes.string,
   /**
   * Link display content
   */
   linkTarget: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * Type of link
   */
   linkType: PropTypes.string
};


