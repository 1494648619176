//https://stackoverflow.com/questions/66095185/how-to-add-a-scroll-event-to-a-header-in-gatsby
import { useLayoutEffect, useRef, startTransition } from 'react';

const getScrollPosition = ({ element, useWindow }) => {
  if (typeof window === `undefined`) return { x: 0, y: 0 };

  const target = element ? element.current : document.body,
    position = target.getBoundingClientRect();

  return useWindow
    ? { x: window.scrollX, y: window.scrollY }
    : { x: position.left, y: position.top };
};

export const useScrollPosition = (effect, deps, element, useWindow, wait) => {

  const position = useRef(getScrollPosition({ useWindow }));
  let throttleTimeout = useRef(null);

  useLayoutEffect(() => {

    const handleScroll = () => {
      startTransition(() => {
        const currentPosition = getScrollPosition({ element, useWindow });
        effect({ previousPosition: position.current, currentPosition: currentPosition });
        position.current = currentPosition;
        throttleTimeout.current = null;
      })
    };

    window.addEventListener(`scroll`, handleScroll);

    return () => window.removeEventListener(`scroll`, handleScroll);

  });

};