//https://usehooks.com/useOnClickOutside/

import { useEffect } from "react";

const useOnClickOutside = (ref, handler) => {
  useEffect(
    () => {
      //handles click outide
      const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
        return;
      };

      //handles escape key
      const escHandler = (event) => {
        if (event.key === 'Escape') {
          const isNotCombinedKey = !(event.ctrlKey || event.altKey || event.shiftKey);
          if (isNotCombinedKey) {
              handler(event)
          }
        }
        if(event.keyCode === 13){
            if (!ref.current || ref.current.contains(event.target)) {
              return;
            }
            handler(event);
         }  
         return;
      }

      document.addEventListener("mouseup", listener);
      document.addEventListener("touchstart", listener);
      document.addEventListener("keydown", escHandler);
      return () => {
        document.removeEventListener("mouseup", listener);
        document.removeEventListener("touchstart", listener);
        document.addEventListener("keydown", escHandler);
      };
    },
    [ref, handler]
  );
}

export default useOnClickOutside